import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Slices from '../components/slices'

const Page = (props) => {
	const pageContent = props.data.prismic.allPages.edges[0].node

	return (
		<Layout>
			<SEO 
				title={pageContent.meta_title[0].text}
				description={pageContent.meta_description[0].text}
				image={pageContent.cover_image} />
			<div className="c-title">
				<RichText render={pageContent.page_title} />
			</div>
			<Slices body={pageContent.body} />
		</Layout>
	)
}

export default Page

export const query = graphql`
	query PageQuery($id: String) {
		prismic {
			allPages(id: $id) {
				edges {
					node {
						page_title
						meta_title
						meta_description
						cover_image
						_meta {
							id
							uid
						}
						body {
							... on PRISMIC_PageBodyParagraph {
								type
								primary {
									paragraph_text
								}
							}
							... on PRISMIC_PageBodyImage {
								type
								primary {
									image
									size
								}
							}
						}
					}
				}
			}
		}
	}
`
  